
import { onMounted, ref } from "vue";

export default {
  props: {
    videoId: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      default: "640",
    },
    height: {
      type: String,
      default: "360",
    },
  },
  setup(props: any) {
    const playerRef = ref<HTMLDivElement | null>(null);
    let YTPLayer: any;

    const youtubeCallbackName = "onYouTubeIframeAPIReady";
    const youtubeExistsFlag = "$isYoutubeFrameAPIReady";

    // Function to load YouTube IFrame API
    const injectYoutubeFrameAPI = () => {
      return new Promise<void>((resolve, reject) => {
        // If the API is already present, resolve immediately
        if (window.YT && window.YT.Player) {
          resolve();
        } else {
          // Load the script only if it's not already present
          if (!(window as any)[youtubeCallbackName]) {
            (window as any)[youtubeCallbackName] = function () {
              (window as any)[youtubeExistsFlag] = true;
              (window as any)[youtubeCallbackName] = null;
              delete (window as any)[youtubeCallbackName];
              resolve();
            };

            const tag = document.createElement("script");
            const firstScript = document.getElementsByTagName("script")[0];
            tag.src = "https://www.youtube.com/iframe_api";
            tag.className = "yt-frame-api";
            firstScript.parentNode?.insertBefore(tag, firstScript);

            // Set a timeout to reject if the API does not load within 15 seconds
            setTimeout(() => {
              if (!(window as any)[youtubeExistsFlag]) {
                reject(new Error("YouTube API loading timeout"));
              }
            }, 15000);
          } else {
            // If the API is already being loaded, we wait for it
            const checkAPIReady = setInterval(() => {
              if (window.YT && window.YT.Player) {
                clearInterval(checkAPIReady);
                resolve();
              }
            }, 100);
          }
        }
      });
    };

    // Function to initialize the player once the API is ready
    const initYouTubePlayer = async () => {
      try {
        // Ensure the API is loaded before creating the player
        await injectYoutubeFrameAPI();

        const playerElement = playerRef.value;
        if (!playerElement) {
          console.error(
            "Error: playerRef.value is null. Cannot initialize YouTube player."
          );
          return;
        }

        // Ensure that the element exists before assigning an ID
        playerElement.id = `player-${Math.floor(
          Math.random() * 1024
        )}-${Date.now()}`;

        // Create the YouTube Player instance
        YTPLayer = new window.YT.Player(playerElement.id, {
          height: props.height || "360",
          width: props.width || "640",
          videoId: props.videoId,
          playerVars: {
            autoplay: 0,
            controls: 0,
            iv_load_policy: 3,
            enablejsapi: 1,
            disablekb: 1,
          },
        });
      } catch (error) {
        console.error("Error initializing YouTube player:", error);
      }
    };

    onMounted(() => {
      initYouTubePlayer();
    });

    return {
      playerRef,
    };
  },
};
