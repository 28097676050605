
import { Breadcrumb, dashboardPath } from "@/components/top-bar";
import useWiseFetch from "@/composables/useWiseFetch";
import { BaseResponse } from "@/services/types";
import { getMemberLearningView } from "@/services/v1/member/learning";
import { LearningMateri } from "@/services/v1/member/learning/types";
import { setBreadcrumb } from "@/utils/breadcrumb";
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import YouTubePlayer from "@/components/youtube-player/Main.vue";

export default defineComponent({
  components: { YouTubePlayer },
  setup() {
    // Ambil route params untuk mendapatkan ID materi video
    const route = useRoute();
    const materiVideoID = computed(() => route.params?.slug as string);

    const breadcrumb: Breadcrumb[] = [
      { title: "Dashboard", to: dashboardPath },
      { title: "Materi Pembelajaran", to: "/member/materi" },
      { title: "Materi Video", disabled: true },
    ];

    onMounted(() => {
      setBreadcrumb(breadcrumb);
    });

    // Ambil data video materi dari API
    const {
      data: dataMateriVideo,
      error,
      execute: fetchMateriVideo,
    } = useWiseFetch<BaseResponse<LearningMateri>>(() => {
      return getMemberLearningView(materiVideoID.value);
    });

    const isLoadedVideo = ref(false);

    const prettierMateriVideo = computed(() => dataMateriVideo.value?.response);

    // Helper function to extract video ID from YouTube URL
    const extractYouTubeVideoID = (url: string): string | null => {
      try {
        const parsedUrl = new URL(url);

        // For short URLs like https://youtu.be/videoId
        if (parsedUrl.hostname === "youtu.be") {
          return parsedUrl.pathname.substring(1);
        }

        // For full URLs like https://www.youtube.com/watch?v=videoId
        if (parsedUrl.hostname.includes("youtube.com")) {
          return parsedUrl.searchParams.get("v");
        }

        return null;
      } catch (error) {
        console.error("Error extracting YouTube video ID:", error);
        return null;
      }
    };

    const extractedVideoId = computed(() => {
      return prettierMateriVideo.value
        ? extractYouTubeVideoID(prettierMateriVideo.value?.content as string)
        : null;
    });

    // Watch untuk extractedVideoId dan set isLoadedVideo menjadi true ketika sudah didapatkan
    watch(extractedVideoId, (newVal) => {
      if (newVal) {
        isLoadedVideo.value = true; // Set to true when video ID is extracted
      }
    });

    // Watch untuk route berubah, ambil ulang data video
    watch(
      materiVideoID,
      (newVal) => {
        if (newVal) {
          fetchMateriVideo();
        }
      },
      { immediate: true }
    );

    return {
      isLoadedVideo,
      prettierMateriVideo,
      error,
      extractedVideoId,
    };
  },
});
