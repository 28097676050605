import { BaseResponse } from "@/services/types";
import { $api } from "@/services/constant";
import { HOST_API_V1 } from "@/services/env-init";
import { LearningCategory, LearningMateri, LearningTopic } from "./types";

const BASE_URL_MEMBER_LEARNING = HOST_API_V1 + "member/learning";

export const getMemberLearningCategories = async (): Promise<
  BaseResponse<LearningCategory[]>
> => {
  const res = await $api().get(`${BASE_URL_MEMBER_LEARNING}/category`);
  return res.data;
};

export const getMemberLearningTopics = async (
  categoryCode: string
): Promise<BaseResponse<LearningTopic[]>> => {
  const res = await $api().get(
    `${BASE_URL_MEMBER_LEARNING}/topic/${categoryCode}`
  );
  return res.data;
};

export const getMemberLearningEbooks = async (
  topicId: string
): Promise<BaseResponse<LearningMateri[]>> => {
  const res = await $api().get(`${BASE_URL_MEMBER_LEARNING}/ebook/${topicId}`);
  return res.data;
};

export const getMemberLearningVideos = async (
  topicId: string
): Promise<BaseResponse<LearningMateri[]>> => {
  const res = await $api().get(`${BASE_URL_MEMBER_LEARNING}/video/${topicId}`);
  return res.data;
};

export const getMemberLearningView = async (
  slug: string
): Promise<BaseResponse<LearningMateri>> => {
  const res = await $api().get(`${BASE_URL_MEMBER_LEARNING}/view/${slug}`);
  return res.data;
};
