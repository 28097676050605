import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "w-full h-full" }
const _hoisted_2 = { class: "flex items-center justify-between h-10 my-5 intro-y" }
const _hoisted_3 = { class: "mr-5 text-lg font-medium truncate" }
const _hoisted_4 = {
  key: 1,
  class: "text-red-500"
}
const _hoisted_5 = { class: "video-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingDiv = _resolveComponent("LoadingDiv")!
  const _component_YouTubePlayer = _resolveComponent("YouTubePlayer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", _hoisted_3, " Materi Video " + _toDisplayString(_ctx.prettierMateriVideo?.title), 1)
    ]),
    (!_ctx.isLoadedVideo && !_ctx.error)
      ? (_openBlock(), _createBlock(_component_LoadingDiv, {
          key: 0,
          class: "mt-5"
        }))
      : _createCommentVNode("", true),
    (_ctx.error)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, " Error loading video: " + _toDisplayString(_ctx.error), 1))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("div", _hoisted_5, [
      (_ctx.extractedVideoId)
        ? (_openBlock(), _createBlock(_component_YouTubePlayer, {
            key: 0,
            videoId: _ctx.extractedVideoId,
            width: "800",
            height: "450"
          }, null, 8, ["videoId"]))
        : _createCommentVNode("", true)
    ], 512), [
      [_vShow, _ctx.isLoadedVideo]
    ])
  ]))
}