import { Ref, ref } from "vue";

type Options = {
  debounce: number;
};

type WiseFetch = <T = any, W = any>(
  handler: (params?: W) => Promise<any>,
  options?: Options,
  params?: W
) => {
  data: Ref<T | null>;
  pending: Ref<boolean>;
  execute: (params?: W) => Promise<void>;
  error: Ref<any | null>;
  reset: () => void;
};

/**
 * @param handler is function / async function
 * @param options so-far only "debounce" with type number
 * @returns
 *   data: Ref<T | null> ,
 *   pending: Ref<boolean> ,
 *   execute: (params?: W) => Promise<void> ,
 *   error: Ref<any | null> ,
 *   reset: () => void ,
 */
const useWiseFetch: WiseFetch = (handler, options) => {
  const fetchData = ref(null);
  const fetchPending = ref(false);
  const fetchError = ref(null);

  let callId = 0;

  const execute = async (params?: any) => {
    callId += 1;
    const currCallId = callId;

    fetchPending.value = true;

    await new Promise((resolve) => setTimeout(resolve, options?.debounce || 0));

    // Another fetch invocation has already been made.
    // This call is stale, we should not continue.
    if (currCallId !== callId) {
      return;
    }

    try {
      const response = await handler(params);
      fetchData.value = response;
      fetchError.value = null;
    } catch (err: any) {
      console.error("err useWiseFetch ", err);
      console.error("err response useWiseFetch ", err?.response);
      fetchError.value = err?.response;
      fetchData.value = null;
    } finally {
      fetchPending.value = false;
    }
  };

  const fetchReset = () => {
    fetchData.value = null;
    fetchPending.value = false;
    fetchError.value = null;
    callId = 0;
  };

  return {
    data: fetchData,
    pending: fetchPending,
    execute,
    error: fetchError,
    reset: fetchReset,
  };
};

export default useWiseFetch;
